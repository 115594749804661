const environment = process.env.REACT_APP_DEPLOYMENT_ENV;

export const USER_POOL_ID =
  environment === "prod"
    ? "ap-southeast-2_nOc6Bq6yc"
    : "ap-southeast-2_KxFFYPfNF";

export const API_URL =
  environment === "prod"
    ? "https://x27ekumr91.execute-api.ap-southeast-2.amazonaws.com/prod"
    : "https://uoevltzhx3.execute-api.ap-southeast-2.amazonaws.com/dev";

// used for xAPI statements
export const PORTAL_API_URL =
  environment === "prod"
    ? "https://qz59iwpcu9.execute-api.ap-southeast-2.amazonaws.com/prod"
    : "https://ckyuctfyz7.execute-api.ap-southeast-2.amazonaws.com/dev";
