import { Grid, Button } from "@mui/material";
import { ReturnToWebsiteButton } from "../components/ReturnToWebsiteButton";
import { TextCard } from "../components/TextCard";
import { questionCount } from "../../../constants/questions";
import { getButtonSxProp } from "../../../utils/getButtonSxProp";

import "./style.css";

interface ScoreSectionProps {
  showScore: boolean;
  finalScore: string;
  handleGenerateReportClick: () => void;
  handleDoNotGenerateReportClick: () => void;
  handleRetakeQuizClick: () => void;
}

export const ScoreSection = ({
  showScore,
  finalScore,
  handleGenerateReportClick,
  handleDoNotGenerateReportClick,
  handleRetakeQuizClick,
}: ScoreSectionProps) => (
  <Grid
    id="score-section-container"
    container
    spacing={1}
    sx={{
      margin: "1em auto",
      display: "flex",
      flexDirection: "column",
      padding: "1em 2em",
      maxWidth: "1000px",
    }}
  >
    <TextCard
      title="Your final score"
      text={
        showScore ? (
          <>
            That's ok, you scored {finalScore} out of {questionCount}. If you
            want to improve your score,{" "}
            <a
              href="https://www.financialtrainingaustralia.com/contact/"
              target="_blank"
              rel="noreferrer"
            >
              contact us
            </a>
            .<br />
            If you've changed your mind, you can still generate your report.
          </>
        ) : (
          "Would you like a report with your score to see how you went and the answers to the quiz?"
        )
      }
      style={{ marginBottom: "40px" }}
    />
    <div>
      <div style={{ textAlign: "center" }}>
        {!showScore ? (
          <div
            className="score-section-button-container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              className="score-section-button"
              variant="contained"
              onClick={handleGenerateReportClick}
              sx={getButtonSxProp("primary", undefined, {
                paddingLeft: "40px",
                paddingRight: "40px",
              })}
            >
              Yes, generate my report
            </Button>
            <Button
              className="score-section-button"
              variant="contained"
              onClick={handleDoNotGenerateReportClick}
              sx={getButtonSxProp("secondary", undefined, {
                paddingLeft: "20px",
                paddingRight: "20px",
              })}
            >
              No, thanks
            </Button>
          </div>
        ) : (
          <div
            className="score-section-button-container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              className="score-section-button"
              variant="contained"
              onClick={handleRetakeQuizClick}
              sx={getButtonSxProp("primary", undefined, {
                paddingLeft: "20px",
                paddingRight: "20px",
                background: "#1FAA9C",
              })}
            >
              Retake self-assessment
            </Button>
            <Button
              className="score-section-button"
              variant="contained"
              onClick={handleGenerateReportClick}
              sx={getButtonSxProp("primary", undefined, {
                paddingLeft: "20px",
                paddingRight: "20px",
              })}
            >
              Generate my report
            </Button>
            <ReturnToWebsiteButton className="score-section-button" />
          </div>
        )}
      </div>
    </div>
  </Grid>
);
