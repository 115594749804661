import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// TODO: ReactDOM.render is no longer supported.
// See: https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
