import React from "react";

import { Grid, TextField, Alert, Select, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormFieldError } from "./components/FormFieldError";
import { Formik, Form, Field } from "formik";
import { TextCard } from "../components/TextCard";

import { User } from "../../../models/api/types";
import { validate } from "../../../utils/validate";
import { getButtonSxProp } from "../../../utils/getButtonSxProp";
import { roleTypes } from "../../../types";

import { useUserDetailsContext } from "../../../contexts/UserDetailsContext";

import "./style.css";

interface FormProps {
  handleSubmit: (values: User) => void;
  loading: boolean;
  error: Error | null;
  loadingMessage: string | null;
}

export const FormSection = ({
  handleSubmit,
  loading,
  error,
  loadingMessage,
}: FormProps) => {
  const { userDetails } = useUserDetailsContext();

  return (
    <Grid
      id="form-section-container"
      container
      sx={{
        margin: "1em auto",
        padding: "1em 2em",
        display: "flex",
        flexDirection: "column",
        maxWidth: "1000px",
      }}
      spacing={1}
    >
      <TextCard
        title="Generate your report"
        text="Enter your details to receive a report with your score and the answers to the self-assessment."
      />
      <div>
        <Formik<User>
          initialValues={{
            fullName: userDetails?.fullName || "",
            phone: userDetails?.phone || "",
            email: userDetails?.email || "",
            organisation: userDetails?.organisation || "",
            roleType: userDetails?.roleType || "Unspecified",
          }}
          onSubmit={(vals) =>
            handleSubmit({ ...vals, email: vals.email.toLowerCase() })
          }
          validate={validate}
        >
          {(f) => {
            return (
              <Form>
                <div className="user-details-input-box">
                  <label htmlFor="fullName" className="user-details-label">
                    Full name*
                  </label>
                  <div>
                    <Field
                      as={TextField}
                      variant="outlined"
                      id="fullName"
                      className="user-details-input"
                      name="fullName"
                      size="small"
                    />
                    <FormFieldError error={f.errors.fullName} />
                  </div>
                </div>
                <div className="user-details-input-box">
                  <label htmlFor="email" className="user-details-label">
                    Email*
                  </label>
                  <div>
                    <Field
                      as={TextField}
                      variant="outlined"
                      id="email"
                      className="user-details-input"
                      name="email"
                      type="email"
                      size="small"
                    />
                    <FormFieldError error={f.errors.email} />
                  </div>
                </div>
                <div className="user-details-input-box">
                  <label htmlFor="phone" className="user-details-label">
                    Phone number*
                  </label>
                  <div>
                    <Field
                      as={TextField}
                      variant="outlined"
                      id="phone"
                      className="user-details-input"
                      name="phone"
                      type="tel"
                      pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                      size="small"
                    />
                    <FormFieldError error={f.errors.phone} />
                  </div>
                </div>
                <div className="user-details-input-box">
                  <label htmlFor="organisation" className="user-details-label">
                    Organisation name*
                  </label>
                  <div>
                    <Field
                      as={TextField}
                      variant="outlined"
                      id="organisation"
                      className="user-details-input"
                      name="organisation"
                      size="small"
                    />
                    <FormFieldError error={f.errors.organisation} />
                  </div>
                </div>
                <div className="user-details-input-box">
                  <label htmlFor="role-type" className="user-details-label">
                    Role type*
                  </label>
                  <div>
                    <Field
                      as={Select}
                      variant="outlined"
                      id="role-type"
                      displayEmpty
                      name="role-type"
                      className="role-type-input"
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                        f.setFieldValue("roleType", event.target.value)
                      }
                      value={f.values.roleType}
                      size="small"
                    >
                      <MenuItem value="Unspecified" disabled>
                        Select a role type...
                      </MenuItem>
                      {roleTypes
                        .filter((roleType) => roleType !== "Unspecified")
                        .map((roleType, i) => (
                          <MenuItem key={i} value={roleType}>
                            {roleType}
                          </MenuItem>
                        ))}
                    </Field>
                    <FormFieldError error={f.errors.roleType} />
                  </div>
                </div>
                <div>
                  {error && (
                    <Alert severity="error" style={{ marginTop: "2em" }}>
                      There was an error submitting your details
                    </Alert>
                  )}
                  <div
                    style={{
                      display: "flex",
                      marginTop: error ? "1em" : "2em",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      sx={getButtonSxProp("primary", undefined, {
                        background: "#1FAA9C",
                        margin: "0 auto",
                      })}
                      loading={loading}
                      disabled={
                        !f.isValid ||
                        !f.values.fullName ||
                        !f.values.email ||
                        !f.values.phone
                      }
                    >
                      Submit
                    </LoadingButton>
                  </div>
                  {!error && loadingMessage && (
                    <div className="loadingMessage">{loadingMessage}</div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Grid>
  );
};
