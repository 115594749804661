interface FormFieldErrorProps {
  error: string | undefined;
}

export const FormFieldError = ({ error }: FormFieldErrorProps) => (
  <div>
    {error && (
      <div
        style={{
          fontSize: "12px",
          color: "red",
          margin: "5px 3px",
          float: "right",
        }}
      >
        {error}
      </div>
    )}
  </div>
);
