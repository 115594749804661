import "./style.css";

interface HeadingProps {
  title: React.ReactNode;
}

const Heading = ({ title }: HeadingProps) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: 15,
      marginTop: "10px",
      marginBottom: "10px",
    }}
  >
    <svg height="22px" width="26px" style={{ display: "inline-block" }}>
      <polygon
        points="0,0 26,0 13,22"
        style={{
          fill: "#DF5236",
          stroke: "#DF5236",
          strokeWidth: 1,
        }}
      />
    </svg>
    <div
      style={{
        fontFamily: "Futura LT",
        fontSize: "20px",
        textTransform: "uppercase",
        fontWeight: "bold",
        color: "#21244A",
        display: "inline-block",
      }}
    >
      {title}
    </div>
  </div>
);

interface TextCardProps {
  title: React.ReactNode;
  text: React.ReactNode;
  style?: React.CSSProperties;
}

export const TextCard = ({ title, text, style }: TextCardProps) => (
  <div style={style}>
    <Heading title={title} />
    <div
      id="text-card-text"
      style={{
        fontWeight: "bold",
        color: "#E1A943",
        paddingLeft: "40px",
        fontSize: "18px",
      }}
    >
      {text}
    </div>
  </div>
);
