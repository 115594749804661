import { Button } from "@mui/material";
import { getButtonSxProp } from "../../../utils/getButtonSxProp";

interface ReturnToWebsiteButtonProps {
  primary?: boolean;
  className?: string;
}

export const ReturnToWebsiteButton = ({
  primary = false,
  className,
}: ReturnToWebsiteButtonProps) => (
  <Button
    className={className}
    variant="contained"
    href="https://www.financialtrainingaustralia.com/"
    sx={getButtonSxProp(primary ? "primary" : "", undefined, {
      paddingLeft: "40px",
      paddingRight: "40px",
    })}
  >
    Return to website
  </Button>
);
