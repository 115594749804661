import { QuestionResponse } from "../types";
import { Responses } from "../models/api/types";
import { QuestionTimes } from "../models/questionTimer/QuestionTimer";

// transformResponses simplifies the data structure to be
// stored for each user, and to generate the email report.
export function transformResponses(
  times: QuestionTimes,
  responses: QuestionResponse[]
) {
  const transformedResponses: Responses = {};

  responses.forEach((response) => {
    transformedResponses[response.questionId] = {
      answer: response.yesResponse,
      time: times[response.questionId].time,
    };
  });

  return transformedResponses;
}
