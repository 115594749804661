import type { Category } from "../../../../../types";
import { getCategoryColour } from "../../utils";

import "./style.css";

interface CategoryHeadingProps {
  category: Category;
}

export const CategoryHeading = ({ category }: CategoryHeadingProps) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: 12,
      marginTop: "10px",
    }}
  >
    <div style={{ minWidth: "22px" }}>
      <svg height="18px" width="22px" style={{ display: "inline-block" }}>
        <polygon
          points="0,0 22,0 11,18"
          style={{
            fill: getCategoryColour(category),
            stroke: getCategoryColour(category),
            strokeWidth: 1,
          }}
        />
      </svg>
    </div>
    <div
      id="category-heading-text"
      style={{
        textTransform: "uppercase",
        fontFamily: "Futura LT",
        fontWeight: "bold",
        fontSize: "20px",
        color: getCategoryColour(category),
        display: "inline-block",
      }}
    >
      {category}
    </div>
  </div>
);
