import React from "react";
import { LogoColour } from "../../assets/images";
import { Container } from "@mui/material";
import { GridShape } from "./GridShape";
import "../../assets/fonts/fonts.css";
import "./style.css";

export const Header = () => {
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );
  const [mobileView, setMobileView] = React.useState(windowWidth < 500);

  React.useEffect(() => {
    setMobileView(windowWidth < 500);
  }, [windowWidth]);

  window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

  return (
    <Container
      sx={{
        background: "#5986B0",
        padding: "35px 0",
        width: "100vw",
        height: "240px",
      }}
      maxWidth={false}
    >
      {!mobileView && (
        <>
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "240px",
              overflow: "hidden",
            }}
          >
            <svg
              height="140px"
              width="160px"
              style={{
                position: "absolute",
                left: -10,
                top: 0,
              }}
            >
              <polygon
                points="0,0 160,0 80,140"
                style={{
                  fill: "white",
                  stroke: "white",
                  strokeWidth: 1,
                }}
              />
            </svg>
            <svg
              height="45px"
              width="52px"
              style={{
                position: "absolute",
                left: 44,
                top: 95,
              }}
            >
              <polygon
                points="0,0 52,0 26,45"
                style={{ fill: "#51428F", stroke: "#51428F", strokeWidth: 1 }}
              />
            </svg>
            <svg
              height="73px"
              width="84px"
              style={{
                position: "absolute",
                left: -9,
                top: 87,
              }}
            >
              <polygon
                points="0,0 84,0 42,73"
                style={{
                  fill: "transparent",
                  stroke: "#1FAA9C",
                  strokeWidth: 1,
                }}
              />
            </svg>
            <svg
              height="49px"
              width="42px"
              style={{ position: "absolute", left: 0, top: 190 }}
            >
              <polygon
                points="0,0 42,0 22,49 0,49"
                style={{ fill: "#21244A", stroke: "#21244A", strokeWidth: 1 }}
              />
            </svg>
            <svg
              height="5px"
              width="55px"
              style={{ position: "absolute", left: 18, top: 213 }}
            >
              <polygon
                points="0,0 55,0 55,4 0,4"
                style={{ fill: "#DF5236", stroke: "#DF5236", strokeWidth: 1 }}
              />
            </svg>
            <svg
              height="75px"
              width="90px"
              style={{
                position: "absolute",
                right: -30,
                top: 50,
              }}
            >
              <polygon
                points="0,0 90,0 45,75"
                style={{ fill: "#1FAA9C", stroke: "#1FAA9C", strokeWidth: 1 }}
              />
            </svg>
            <svg
              height="90px"
              width="100px"
              style={{
                position: "absolute",
                right: 10,
                top: 100,
              }}
            >
              <polygon
                points="0,90 50,0 100,90"
                style={{
                  fill: "transparent",
                  stroke: "white",
                  strokeWidth: 1,
                  strokeDasharray: "1 2",
                }}
              />
            </svg>
            <svg
              height="50px"
              width="50px"
              style={{
                position: "absolute",
                right: 12,
                top: 130,
              }}
            >
              <polygon
                points="0,0 40,0 20,35"
                style={{ fill: "#DF5236", stroke: "#DF5236", strokeWidth: 1 }}
              />
            </svg>
            <svg
              height="50px"
              width="50px"
              style={{ position: "absolute", right: -35, top: 135 }}
            >
              <polygon
                points="0,0 40,0 20,35"
                style={{ fill: "#21244A", stroke: "#21244A", strokeWidth: 1 }}
              />
            </svg>
            <svg
              height="90px"
              width="100px"
              style={{ position: "absolute", right: -65, top: 150 }}
            >
              <polygon
                points="0,90 50,0 100,90"
                style={{
                  fill: "#51428F",
                  stroke: "#51428F",
                  strokeWidth: 1,
                }}
              />
            </svg>
            <svg
              height="90px"
              width="100px"
              style={{
                position: "absolute",
                right: 120,
                top: -40,
              }}
            >
              <polygon
                points="0,90 50,0 100,90"
                style={{
                  fill: "transparent",
                  stroke: "#1FAA9C",
                  strokeWidth: 1,
                  strokeDasharray: "2 3",
                }}
              />
            </svg>
            <>
              <GridShape
                colour="#1FAA9C"
                style={{ top: "115px", left: "90px" }}
              />
              <GridShape
                colour="white"
                style={{ top: "10px", right: "100px" }}
              />
            </>
          </div>
          <a
            href="https://www.financialtrainingaustralia.com"
            target="_blank"
            rel="noreferrer"
            style={{
              position: "absolute",
              display: "block",
              width: "fit-content",
              top: "26px",
              left: "34px",
            }}
          >
            <img
              src={LogoColour}
              alt="LogoColour"
              style={{
                height: "40px",
                left: "40px",
              }}
            />
          </a>
        </>
      )}
      <div
        id="header-heading"
        style={{
          fontFamily: "Futura LT",
          fontWeight: "bold",
          fontSize: "50px",
          color: "white",
          textTransform: "uppercase",
          textAlign: "center",
        }}
      >
        Financial
        <br />
        Acumen
      </div>
      <div
        id="header-subheading"
        style={{
          fontSize: "30px",
          color: "white",
          textAlign: "center",
        }}
      >
        Self-Assessment
      </div>
    </Container>
  );
};
