import {
  xAPIStatementType,
  xAPIStatementResultType,
} from "../models/api/types";
import { PORTAL_API_URL } from "../awsConfig";
export const generatexAPIStatement = ({
  actorInput,
  instanceInput,
  verbInput,
  questionInput,
  resultInput,
}: {
  actorInput: string;
  instanceInput: string;
  verbInput: "attempted" | "answered" | "completed";
  questionInput: string | null;
  resultInput: xAPIStatementResultType | null;
}) => {
  const statement: xAPIStatementType = {
    actor: {
      name: "FASAT Anonymous Users",
      objectType: "Group",
      account: {
        objectType: "Group",
        member: [
          {
            objectType: "Agent",
            account: {
              homepage: "https://portal.financialtrainingaustralia.com/",
              name: actorInput,
            },
          },
        ],
      },
    },
    instance: {
      id: instanceInput,
      definition: {
        name: {
          "en-US": "Financial Acumen Self-Assessment Tool",
        },
        description: {
          "en-US": "Financial Acumen Self-Assessment Tool",
        },
        type: "http://adlnet.gov/expapi/activities/course",
        interactionType: "other",
      },
      objectType: "Activity",
    },
    verb: {
      id: `http://adlnet.gov/expapi/verbs/${verbInput}`,
      display: {
        "en-US": verbInput,
      },
    },
    object: {
      id: instanceInput,
      definition: {
        name: {
          "en-US": "Financial Acumen Self-Assessment Tool",
        },
        type: "http://adlnet.gov/expapi/activities/course",
        interactionType: "other",
        extensions: {
          courseId: "https://portal.financialtrainingaustralia.com/id=fasat",
        },
      },
      objectType: "Activity",
    },
  };
  if (questionInput) {
    statement.object.definition.description = {
      "en-US": questionInput.replace(/,/g, "&#44;"),
    };
  }
  if (resultInput) {
    statement.result = resultInput;
  }
  fetch(`${PORTAL_API_URL}/processxAPIStatements`, {
    method: "POST",
    body: JSON.stringify(statement),
  });
};
