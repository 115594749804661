import { Question, Category } from "../types";

// An ordered list of questions.
export const questions: Question[] = [
  {
    questionId: 1,
    text: "Do you understand the Income Statement?",
    category: Category.FinancialReports,
  },
  {
    questionId: 2,
    text: "Do you understand which lines of the Income Statement you can influence?",
    category: Category.FinancialReports,
  },
  {
    questionId: 3,
    text: "Can you read a Balance Sheet or a Statement of Financial Position?",
    category: Category.FinancialReports,
  },
  {
    questionId: 4,
    text: "Do you know the definition of a Current, versus a Non Current, Asset and its relevance?",
    category: Category.FinancialReports,
  },
  {
    questionId: 5,
    text: "Do you understand the difference between Accrual and Cash Accounting?",
    category: Category.FinancialReports,
  },
  {
    questionId: 6,
    text: "Do you understand how to review the Cash Flow Statement?",
    category: Category.FinancialReports,
  },
  {
    questionId: 7,
    text: "Do you understand the concept of Depreciation and Amortisation?",
    category: Category.FinancialReports,
  },
  {
    questionId: 8,
    text: "Do you understand how GST is treated and which of the 3 key financial statements are going to be inclusive of GST and which exclusive?",
    category: Category.FinancialReports,
  },
  {
    questionId: 9,
    text: "Do you know the key financial ratios used by your organisation?",
    category: Category.FinancialRatios,
  },
  {
    questionId: 10,
    text: "Do you know how to calculate EBIT and EBITDA and what each measures?",
    category: Category.FinancialRatios,
  },
  {
    questionId: 11,
    text: "Do you know which Liquidity ratios are most relevant to your organisation?",
    category: Category.FinancialRatios,
  },
  {
    questionId: 12,
    text: "Do you understand the difference between mark up and margin?",
    category: Category.FinancialRatios,
  },
  {
    questionId: 13,
    text: "Do you understand how DSO, DIO and DPO are calculated and their relevance to your organisation?",
    category: Category.FinancialRatios,
  },
  {
    questionId: 14,
    text: "Do you know which ROI measures are used?",
    category: Category.FinancialRatios,
  },
  {
    questionId: 15,
    text: "In reading company annual reports do you understand basic EPS and diluted EPS?",
    category: Category.FinancialRatios,
  },
  {
    questionId: 16,
    text: "Could you articulate the financial objectives of your organisation?",
    category: Category.BusinessFinance,
  },
  {
    questionId: 17,
    text: "Could you describe the financial strengths, constraints and challenges of your organisation?",
    category: Category.BusinessFinance,
  },
  {
    questionId: 18,
    text: "Have you had business cases approved (where you were responsible for the financial content)?",
    category: Category.BusinessFinance,
  },
  {
    questionId: 19,
    text: "Do you understand what is involved in creating value for your organisation?",
    category: Category.BusinessFinance,
  },
  {
    questionId: 20,
    text: "Do you understand the concepts of Payback, NPV and IRR?",
    category: Category.BusinessFinance,
  },
  {
    questionId: 21,
    text: "Do you feel confident in preparing an operating budget on an accrual accounting basis?",
    category: Category.BudgetingAndCosting,
  },
  {
    questionId: 22,
    text: "Could you prepare a cash flow budget?",
    category: Category.BudgetingAndCosting,
  },
  {
    questionId: 23,
    text: "Do you understand Zero Based budgeting and why it became popular during the GFC?",
    category: Category.BudgetingAndCosting,
  },
  {
    questionId: 24,
    text: " Do you understand the true costs of the services your area provides – both direct and indirect?",
    category: Category.BudgetingAndCosting,
  },
  {
    questionId: 25,
    text: "Do you understand how indirect costs are allocated?",
    category: Category.BudgetingAndCosting,
  },
  {
    questionId: 26,
    text: "There are 4 variables which drive Revenue (Revenue = C x F x T x P) – can you identify them?",
    category: Category.BudgetingAndCosting,
  },
  {
    questionId: 27,
    text: "Do you know the key costs drivers for your area?",
    category: Category.BudgetingAndCosting,
  },
  {
    questionId: 28,
    text: "Do you understand the difference between Opex and Capex and their different accounting treatments?",
    category: Category.BudgetingAndCosting,
  },
  {
    questionId: 29,
    text: "Do you understand the differences between price, volume and timing variances and which are permanent and which temporary?",
    category: Category.BudgetingAndCosting,
  },
  {
    questionId: 30,
    text: "Could you predict the actual results for your area at the end of each month before you receive the report from finance?",
    category: Category.BudgetingAndCosting,
  },
];

export const questionCount = questions.length;

