import React from "react";
import { Grid, Button } from "@mui/material";
import { Category, Question, QuestionResponse } from "../../../../../types";
import { getButtonSxProp } from "../../../../../utils/getButtonSxProp";
import { CategoryHeading } from "../CategoryHeading";
import { QuestionContainer } from "../QuestionContainer";
import { questions } from "../../../../../constants/questions";

interface QuestionSectionProps {
  category: Category;
  handleNextClick: (category: Category) => void;
  responses: QuestionResponse[];
  setResponses: React.Dispatch<React.SetStateAction<QuestionResponse[]>>;
  tempUserId: string;
  tempInstanceId: string;
}

export const QuestionsContainer = ({
  category,
  handleNextClick,
  responses,
  setResponses,
  tempUserId,
  tempInstanceId,
}: QuestionSectionProps) => {
  const categorisedQuestions = React.useMemo(
    () => questions.filter((q) => q.category === category),
    [category]
  );

  const allQuestionsAnswered = React.useMemo(
    () =>
      categorisedQuestions.every((question) =>
        responses.some(
          (response) => response?.questionId === question.questionId
        )
      ),
    [responses, categorisedQuestions]
  );

  return (
    <Grid item id="question-container">
      <Grid item sx={{ margin: "0.5em 0 1.5em 0" }}>
        <CategoryHeading category={category} />
      </Grid>
      <Grid item>
        {categorisedQuestions?.map((question: Question) => (
          <QuestionContainer
            key={question.questionId}
            question={question}
            responses={responses}
            setResponses={setResponses}
            tempUserId={tempUserId}
            tempInstanceId={tempInstanceId}
          />
        ))}

        <div
          id="question-card-button-container"
          style={{
            marginTop: "2em",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            type="button"
            sx={getButtonSxProp("primary", category)}
            onClick={() => handleNextClick(category)}
            disabled={!allQuestionsAnswered}
          >
            Next
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
