import { User } from "../models/api/types";
import { RoleType } from "../types";

// TODO: add email and phone validation funcs

function fieldRequired(fieldValue: string) {
  return fieldValue ? null : "Required";
}

function roleTypeRequired(fieldValue: RoleType) {
  return fieldValue && fieldValue !== "Unspecified" ? null : "Required";
}

// Formik validation
export function validate(values: User) {
  const errors: {
    [P in keyof User]?: string;
  } = {};

  const nameRequiredErr = fieldRequired(values.fullName);
  if (nameRequiredErr) {
    errors.fullName = nameRequiredErr;
  }

  const emailRequiredErr = fieldRequired(values.email);
  if (emailRequiredErr) {
    errors.email = emailRequiredErr;
  }

  const phoneRequiredErr = fieldRequired(values.phone);
  if (phoneRequiredErr) {
    errors.phone = phoneRequiredErr;
  }

  const organisationRequiredErr = fieldRequired(values.organisation);
  if (organisationRequiredErr) {
    errors.organisation = organisationRequiredErr;
  }

  const roleTypeRequiredErr = roleTypeRequired(values.roleType);
  if (roleTypeRequiredErr) {
    errors.roleType = roleTypeRequiredErr;
  }

  return errors;
}
