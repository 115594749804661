import React, { useContext, useEffect, useMemo } from "react";
import { UserDetails } from "../types";
import { getUserData, getOrgData } from "../models/api/fetch";

interface UserDetailsContextType {
  userDetails?: UserDetails;
  userId?: string;
  instanceId?: string;
}

export const UserDetailsContext = React.createContext<UserDetailsContextType>(
  null!
);

export function useUserDetailsContext() {
  const context = useContext(UserDetailsContext);

  if (!context) {
    throw new Error(
      "UserDetailsContext used outside of UserDetailsContext provider"
    );
  }

  return context;
}

const UserDetailsProvider = (props: any) => {
  const [userDetails, setUserDetails] = React.useState<UserDetails | undefined>(
    undefined
  );

  const searchParams = new URLSearchParams(window.location.search);
  const orgId = searchParams.get("orgId");
  const userId = searchParams.get("userId") || "";
  const instanceId = searchParams.get("instanceId") || "";

  const fetchUserData = React.useCallback(async (id: string) => {
    const responseUser = await getUserData(id);

    if (!responseUser) return;
    setUserDetails({
      fullName: `${responseUser.data.data.userFirstName} ${responseUser.data.data.userLastName}`,
      phone: responseUser.data.data.userPhone,
      organisation: responseUser.data.data.userOrganization,
      email: responseUser.data.data.userEmail,
    });
  }, []);

  const fetchOrgData = React.useCallback(async (id: string) => {
    const responseOrg = await getOrgData(id);
    if (!responseOrg) return;
    setUserDetails({
      organisation: responseOrg.data.data.orgName,
    });
  }, []);

  useEffect(() => {
    try {
      if (userId) {
        fetchUserData(userId);
      } else if (orgId) {
        fetchOrgData(orgId);
      }
    } catch (error) {
      console.log(error);
    }
  }, [fetchUserData, fetchOrgData, userId, orgId]);

  const contextValue: UserDetailsContextType = useMemo(
    () => ({
      userDetails,
      userId,
      instanceId,
    }),
    [userDetails, userId, instanceId]
  );

  return <UserDetailsContext.Provider value={contextValue} {...props} />;
};

export default UserDetailsProvider;
