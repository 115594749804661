import { questionCount } from "../../constants/questions";

export interface QuestionTimes {
  [key: number]: {
    // Time the user started the question in Unix milliseconds
    start: number;
    // Total time the user spent on the question
    time: number;
  };
}

const questionTimesInitialiser = () => {
  const defaultTimes: QuestionTimes = {};
  for (let i = 1; i <= questionCount; i++) {
    defaultTimes[i] = {
      start: 0,
      time: 0,
    };
  }

  return defaultTimes;
};

const handleInvalidQuestionNumberErr = (questionNumber: number) => {
  if (questionNumber <= 0 || questionNumber > questionCount) {
    throw new Error(
      `Invalid getQuestionTime arg (${questionNumber}). Number must be between 1 and ${questionCount}.`
    );
  }
};

export class QuestionTimer {
  times: QuestionTimes = questionTimesInitialiser();

  // Calculate time in seconds from question start time
  private calculateTime(questionNumber: number) {
    const timeElapsedInMilliseconds =
      Date.now() - this.times[questionNumber].start;
    return Math.round(timeElapsedInMilliseconds / 1000);
  }

  // Set start time in Unix milliseconds
  public setStartTime(questionNumber: number) {
    handleInvalidQuestionNumberErr(questionNumber);
    this.times[questionNumber].start = Date.now();
  }

  // Set time in seconds from question start time
  public setTime(questionNumber: number) {
    handleInvalidQuestionNumberErr(questionNumber);
    this.times[questionNumber].time += this.calculateTime(questionNumber);
    return this.times[questionNumber].time;
  }

  public resetTimes() {
    this.times = questionTimesInitialiser();
  }
}
