export interface Question {
  questionId: number;
  text: string;
  category: Category;
}

export interface QuestionResponse extends Question {
  yesResponse: boolean;
}

export enum Category {
  FinancialReports = "Understanding your organisation's financial reports",
  FinancialRatios = "Key financial ratios",
  BusinessFinance = "Business finance",
  BudgetingAndCosting = "Budgeting and costing",
}

export const roleTypes = [
  "Unspecified",
  "Board Director",
  "Executive",
  "Finance",
  "Operations",
  "HR",
  "Learning & Development",
  "Sales",
  "Purchasing",
  "IT",
  "Other",
] as const;

export type RoleType = (typeof roleTypes)[number];

export enum SectionType {
  QUESTION = "question",
  NAVIGATE = "navigate",
  SCORE = "score",
  FORM = "form",
  END = "end",
}

export interface UserDetails {
  fullName?: string;
  email?: string;
  organisation?: string;
  phone?: string;
  roleType?: RoleType;
  // tempUserId is used to identify a users interactions via xAPI before they have created an account
  tempUserId?: string;
}
