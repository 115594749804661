import React from "react";
import { Category } from "../../../../../types";
import { Button, Grid } from "@mui/material";
import {
  BankStatementIcon,
  BudgetIcon,
  PortfolioIcon,
  ReportIcon,
} from "../../../../../assets/images";
import { getCategoryColour } from "../../utils";
import "./style.css";
import { getButtonSxProp } from "../../../../../utils/getButtonSxProp";

interface CategoryCardProps {
  category: Category;
  handleEnterCategory: (category: Category) => void;
  index: number;
  answeredCategories: Record<Category, boolean>;
}

export const CategoryCard = ({
  category,
  handleEnterCategory,
  index,
  answeredCategories,
}: CategoryCardProps) => {
  const answered = React.useMemo(
    () => answeredCategories[category],
    [answeredCategories, category]
  );

  return (
    <Grid
      container
      id="category-container"
      key={index}
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #E0E0E0",
        borderRadius: "10px",
        padding: "1em",
        margin: "1em 0",
        backgroundColor: answered ? "#F9F9FB" : "white",
      }}
    >
      <div
        className="question-category-icon"
        style={{
          display: "flex",
          height: "70px",
          width: "70px",
          borderRadius: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F9F9FB",
        }}
      >
        <img
          src={
            category === Category.FinancialReports
              ? BankStatementIcon
              : category === Category.FinancialRatios
              ? BudgetIcon
              : category === Category.BusinessFinance
              ? PortfolioIcon
              : ReportIcon
          }
          alt=""
          id="category-icon"
          height="40px"
        />
      </div>
      <div
        id="category-section-heading-text"
        style={{
          textTransform: "uppercase",
          fontFamily: "Futura LT",
          fontWeight: "bold",
          fontSize: "20px",
          color: answered ? "darkgrey" : getCategoryColour(category),
          display: "flex",
          marginLeft: "1em",
          flexGrow: 1,
        }}
      >
        {category}
      </div>
      <Grid item id="take-quiz-button">
        <Button
          variant="contained"
          type="button"
          onClick={() => handleEnterCategory(category)}
          sx={getButtonSxProp("primary", category)}
        >
          {answered ? "Edit" : "Take it!"}
        </Button>
      </Grid>
    </Grid>
  );
};
