// getButtonSxProp returns custom styles passed to the
import { getCategoryColour } from "../components/sections/question/utils";
import { Category } from "../types";

function getOverrideBackground(styles: React.CSSProperties | undefined) {
  if (!styles) return;
  if (styles["background"]) return styles["background"];
  return;
}

// Material UI button component sx prop.
export function getButtonSxProp(
  variant: string,
  category?: Category,
  overrideStyles?: React.CSSProperties
) {
  const isPrimary = variant === "primary";
  const primaryColour = category ? getCategoryColour(category) : "#DF5236";

  const hoverStyles = {
    background:
      getOverrideBackground(overrideStyles) ??
      (isPrimary ? primaryColour : "#21244A"),
    boxShadow: "0px 0px 0px 2px #lightGrey",
  };

  return {
    minWidth: "7em",
    marginLeft: "0.5em",
    padding: "0.6em 1em",
    margin: "0 0.3em",
    fontFamily: "Futura",
    fontSize: "14px",
    border: "none",
    color: "white",
    background: isPrimary ? primaryColour : "#21244A",
    cursor: "pointer",
    borderRadius: "4px",
    boxShadow: 0,
    textTransform: "initial",
    // TODO: make shades darker
    "&:hover": hoverStyles,
    ...overrideStyles,
  };
}
