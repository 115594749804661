interface GridShapeProps {
  colour: string;
  columns?: number;
  rows?: number;
  style?: React.CSSProperties;
}

export const GridShape = ({
  colour,
  columns = 4,
  rows = 6,
  style,
}: GridShapeProps) => {
  let gridTemplateColumns = "";
  for (let i = 0; i < columns; i++) {
    gridTemplateColumns += "auto";
    if (i !== columns - 1) gridTemplateColumns += " ";
  }

  return (
    <div
      style={{
        width: "50px",
        height: "75px",
        display: "grid",
        gridTemplateColumns,
        position: "absolute",
        ...style,
      }}
    >
      {Array.from({ length: rows * columns }).map((_, i) => (
        <div
          key={i + 1}
          style={{ width: "3px", height: "3px", background: colour }}
        />
      ))}
    </div>
  );
};
