import { Responses, User } from "./types";
import { TalliedScores } from "./types";
import generatePassword from "../../utils/generatePassword";
import { USER_POOL_ID, API_URL } from "../../awsConfig";
import axios from "axios";

export async function getUserData(userId: string) {
  try {
    const postData = {
      userId,
    };

    const userData = await axios.post(
      `${API_URL}/getUserData`,
      JSON.stringify(postData)
    );

    return userData;
  } catch (err) {
    console.log(err);
  }
}

export async function getOrgData(orgId: string) {
  try {
    const postData = {
      orgId,
    };

    const orgData = await axios.post(
      `${API_URL}/getOrgData`,
      JSON.stringify(postData)
    );

    return orgData;
  } catch (err) {
    console.log(err);
  }
}

export function deleteTempxAPIData(userId: string) {
  try {
    return axios.post(`${API_URL}/deleteTempxApiData`, {
      userId,
    });
  } catch (err) {
    console.log(err);
  }
}

export async function createUser(
  user: User,
  responses: Responses,
  userId: string,
  instanceId?: string
) {
  const response = await fetch(`${API_URL}/users`, {
    method: "POST",
    body: JSON.stringify({ ...user, responses, userId, instanceId }),
  });

  if (!response.ok) {
    const errResponse = await response.json();
    throw new Error(JSON.stringify(errResponse));
  }

  return await response.json();
}

export async function createUserPortal(
  user: User,
  username: string,
  userTempPassword: string,
  instanceId?: string
) {
  const requestBody = { ...user, username, userTempPassword, instanceId };

  const response = await fetch(`${API_URL}/createUserPortal`, {
    method: "POST",
    body: JSON.stringify(requestBody),
  });
  console.log(response, "create user portal");

  if (!response.ok) {
    const errResponse = await response.json();
    throw new Error(JSON.stringify(errResponse));
  }

  return await response.json();
}

export async function sendEmail(
  user: User,
  responses: Responses,
  scores: TalliedScores
) {
  const body = {
    fullName: user.fullName,
    email: user.email,
    responses,
    scores,
  };

  const response = await fetch(`${API_URL}/report`, {
    method: "POST",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const errResponse = await response.json();
    throw new Error(JSON.stringify(errResponse));
  }

  return await response.json();
}

export async function adminCreateUser(email: String, userPoolId: string) {
  try {
    const postData = {
      temporaryPassword: generatePassword(true, true, true, true, 20),
      userName: email,
      userPoolId,
    };

    const adminCreateUserResponse = await axios.post(
      `${API_URL}/adminCreateUser`,
      JSON.stringify(postData)
    );

    return adminCreateUserResponse;
  } catch (err: any) {
    return { data: { success: false, data: err.message } };
  }
}

export async function addUserToGroup(Username: string) {
  try {
    const postData = {
      GroupName: "Client",
      Username,
      UserPoolId: USER_POOL_ID,
    };

    const addUserToGroupResponse = await axios.post(
      `${API_URL}/addUserToGroup`,
      JSON.stringify(postData)
    );

    return addUserToGroupResponse.data.Username;
  } catch (err) {
    console.log(err);
  }
}

export async function getUsername(email: string) {
  try {
    const postData = {
      email,
      UserPoolId: USER_POOL_ID,
    };

    const userdetails = await axios.post(
      `${API_URL}/getUsername`,
      JSON.stringify(postData)
    );

    return userdetails;
  } catch (err) {
    console.log(err);
  }
}
