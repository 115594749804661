import { Grid } from "@mui/material";
import { TextCard } from "../components/TextCard";
import { ReturnToWebsiteButton } from "../components/ReturnToWebsiteButton";

import "./style.css";

interface EndSectionProps {
  email: string;
}

export const EndSection = ({ email }: EndSectionProps) => (
  <Grid
    id="end-section-container"
    container
    spacing={1}
    style={{
      margin: "1em auto",
      padding: "1em 2em",
      maxWidth: "1000px",
    }}
  >
    <TextCard
      title="Thank you!"
      text={`A report containing your score and the self-assessment answers will be sent to your inbox at ${email}.`}
      style={{ marginBottom: "40px" }}
    />
    <div style={{ width: "100%", textAlign: "center" }}>
      <ReturnToWebsiteButton />
    </div>
  </Grid>
);
