import { Category, QuestionResponse } from "../types";
import { TalliedScores } from "../models/api/types";

export function tallyScores(responses: QuestionResponse[]) {
  let financialReportsScore = 0;
  let financialRatiosScore = 0;
  let businessFinanceScore = 0;
  let budgetingAndCostingScore = 0;

  responses.forEach((response, i) => {
    if (!response.yesResponse) return;
    switch (response.category) {
      case Category.FinancialReports:
        financialReportsScore += 1;
        break;
      case Category.FinancialRatios:
        financialRatiosScore += 1;
        break;
      case Category.BusinessFinance:
        businessFinanceScore += 1;
        break;
      case Category.BudgetingAndCosting:
        budgetingAndCostingScore += 1;
        break;
      default:
        throw new Error(
          `Question category "${response.category}" has not been registered`
        );
    }
  });

  const total =
    financialReportsScore +
    financialRatiosScore +
    businessFinanceScore +
    budgetingAndCostingScore;

  return {
    financialReportsScore,
    financialRatiosScore,
    businessFinanceScore,
    budgetingAndCostingScore,
    total,
  } as TalliedScores;
}
