import React from "react";
import { LogoColour, LogoWhite } from "../../assets/images";
import { GridShape } from "../Header/GridShape";
import { SectionType } from "../../types";

interface FooterProps {
  sectionType: SectionType;
}

export const Footer = ({ sectionType }: FooterProps) => {
  const [windowHeight, setWindowHeight] = React.useState<number>(
    window.innerHeight
  );
  const [noFooterView, setNoFooterView] = React.useState(windowHeight < 820);
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );
  const [mobileView, setMobileView] = React.useState(windowWidth < 500);

  React.useEffect(() => {
    setNoFooterView(windowHeight < 820);
  }, [windowHeight]);

  React.useEffect(() => {
    setMobileView(windowWidth < 500);
  }, [windowWidth]);

  window.addEventListener("resize", () => setWindowHeight(window.innerHeight));
  window.addEventListener("resize", () => setWindowWidth(window.innerWidth));

  // Don't show the footer if the viewport height is < 820px
  if (noFooterView)
    return (
      <div style={{ width: "100%", textAlign: "right", marginTop: "2rem" }}>
        <a
          href="https://www.financialtrainingaustralia.com"
          target="_blank"
          rel="noreferrer"
          style={{
            display: "block",
          }}
        >
          <img
            src={LogoColour}
            alt="LogoColour"
            style={{
              height: "40px",
              marginRight: "10px",
            }}
          />
        </a>
      </div>
    );

  return (
    <div
      style={{
        background: "#5986B0",
        padding: "40px 0",
        width: "100vw",
        height: "150px",
        position: "relative",
        bottom: 0,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          width: "100px",
          height: "150px",
        }}
      >
        <svg
          height="60px"
          width="70px"
          style={{
            position: "absolute",
            left: !mobileView ? 50 : 10,
            top: !mobileView ? 25 : 60,
          }}
        >
          <polygon
            points="0,60 35,0 70,60"
            style={{
              fill: "#1FAA9C",
              stroke: "#1FAA9C",
              strokeWidth: 1,
            }}
          />
        </svg>
        <svg
          height="25px"
          width="30px"
          style={{
            position: "absolute",
            left: !mobileView ? 120 : 80,
            top: !mobileView ? 75 : 105,
          }}
        >
          <polygon
            points="0,0 30,0 15,25"
            style={{
              fill: "#51428F",
              stroke: "#51428F",
              strokeWidth: 1,
            }}
          />
        </svg>
        <GridShape colour="#1FAA9C" style={{ top: -30, right: 20 }} />
      </div>
      <a
        href="https://www.financialtrainingaustralia.com"
        target="_blank"
        rel="noreferrer"
        style={{ display: "block", margin: "0 auto", width: "fit-content" }}
      >
        <img src={LogoWhite} alt="LogoWhite" style={{ height: "70px" }} />
      </a>
    </div>
  );
};
