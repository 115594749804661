import { Category } from "../../../types";

export function getCategoryColour(category: Category) {
  switch (category) {
    case Category.FinancialReports:
      return "#21244A";
    case Category.FinancialRatios:
      return "#51428F";
    case Category.BusinessFinance:
      return "#5986B0";
    case Category.BudgetingAndCosting:
      return "#1FAA9C";
  }
}
