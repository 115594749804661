import React from "react";
import { Radio } from "../Radio";
import { getCategoryColour } from "../../utils";
import { Question, QuestionResponse } from "../../../../../types";
import { generatexAPIStatement } from "../../../../../utils/generatexAPIStatement";
import moment from "moment";
import { QuestionTimer } from "../../../../../models/questionTimer/QuestionTimer";
import { useUserDetailsContext } from "../../../../../contexts/UserDetailsContext";

interface QuestionProps {
  question: Question;
  responses: QuestionResponse[];
  setResponses: React.Dispatch<React.SetStateAction<QuestionResponse[]>>;
  tempUserId: string;
  tempInstanceId: string;
}

const timer = new QuestionTimer();

export const QuestionContainer = ({
  question,
  responses,
  setResponses,
  tempUserId,
  tempInstanceId,
}: QuestionProps) => {
  const questionIndex = question.questionId - 1;
  const { userId, instanceId } = useUserDetailsContext();

  React.useEffect(() => {
    timer.setStartTime(1);
  }, []);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const yesResponse = event.target.value === "yes";
    let newResponses = [...responses];
    newResponses[questionIndex] = {
      questionId: question.questionId,
      yesResponse: yesResponse,
      text: question.text,
      category: question.category,
    };
    setResponses(newResponses);

    const time = timer.setTime(question.questionId);
    timer.setStartTime(question.questionId);
    generatexAPIStatement({
      actorInput: userId || tempUserId,
      instanceInput: instanceId || tempInstanceId,
      verbInput: "answered",
      questionInput: question.text,
      resultInput: {
        response: yesResponse ? "yes" : "no",
        duration: moment.duration(time, "seconds").toISOString(),
        completion: true,
        success: true,
      },
    });
  };

  return (
    <div
      id="text-container-full"
      style={{
        display: "flex",
        height: "80px",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      <div
        id="question-number"
        style={{
          height: "100%",
          width: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "35px",
          fontWeight: "bold",
          color: "white",
          border: `1px solid ${getCategoryColour(question.category)}`,
          background: getCategoryColour(question.category),
        }}
      >
        {question.questionId}
      </div>
      <div
        id="text-container"
        style={{
          height: "100%",
          flex: 1,
          border: "1px dashed grey",
          display: "flex",
        }}
      >
        <div
          id="question-text"
          style={{
            fontFamily: "Futura LT",
            fontWeight: "bold",
            padding: "15px",
            fontSize: "18px",
            flex: 1,
            color: "#E1A943",
            overflow: "auto",
          }}
        >
          {question.text}
        </div>
        <div
          id="question-checkbox-container"
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              fontSize: "15px",
              marginRight: "10px",
            }}
          >
            <Radio
              name={`question_${question.questionId}_answer`}
              label="Yes"
              value="yes"
              onChange={handleRadioChange}
              checked={responses[questionIndex]?.yesResponse === true}
            />
          </div>
          <div style={{ display: "inline-block", fontSize: "15px" }}>
            <Radio
              name={`question_${question.questionId}_answer`}
              label="No"
              value="no"
              onChange={handleRadioChange}
              checked={responses[questionIndex]?.yesResponse === false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
