import React from "react";
import "./style.css";

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export const Radio = (props: RadioProps) => {
  const { label, ...rest } = props;

  return (
    <div style={{ display: "flex" }}>
      <div style={{ display: "inline-flex", padding: "0.2em" }}>
        <input id="custom-radio" type="radio" {...rest} />
        <label style={{ paddingTop: "2px" }}>{label}</label>
      </div>
    </div>
  );
};
