import React from "react";

import { Container } from "@mui/material";
import { Header, Footer } from "./components";
import {
  QuestionSection,
  ScoreSection,
  FormSection,
  EndSection,
} from "./components/sections";

import { QuestionTimer } from "./models/questionTimer/QuestionTimer";
import { generatexAPIStatement } from "./utils/generatexAPIStatement";

import {
  createUser,
  createUserPortal,
  sendEmail,
  adminCreateUser,
  addUserToGroup,
  getUsername,
  deleteTempxAPIData,
} from "./models/api/fetch";

import { TalliedScores, User } from "./models/api/types";
import { SectionType, QuestionResponse, Category } from "./types";
import { USER_POOL_ID } from "./awsConfig";

import { transformResponses } from "./utils/transformResponses";
import { tallyScores } from "./utils/tallyScores";

import UserDetailsProvider, {
  useUserDetailsContext,
} from "./contexts/UserDetailsContext";

import { v4 as uuidv4 } from "uuid";

import "./style.css";

const timer = new QuestionTimer();
const tempUserId = uuidv4();
const tempInstanceId = uuidv4();

function AppInner() {
  const [sectionType, setSectionType] = React.useState<SectionType>(
    SectionType.QUESTION
  );
  const [showFinalScore, setShowFinalScore] = React.useState(false);
  const [finalScore, setFinalScore] = React.useState(0);
  const [talliedScores, setTalliedStores] = React.useState<TalliedScores>({
    financialReportsScore: 0,
    financialRatiosScore: 0,
    businessFinanceScore: 0,
    budgetingAndCostingScore: 0,
    total: 0,
  });
  const [loadingMessage, setLoadingMessage] = React.useState("");
  const [responses, setResponses] = React.useState<QuestionResponse[]>([]);

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [userEmail, setUserEmail] = React.useState("");

  const [category, setCategory] = React.useState<Category | null>(null);
  const [answeredCategories, setAnsweredCategories] = React.useState<
    Record<Category, boolean>
  >({
    "Understanding your organisation's financial reports": false,
    "Key financial ratios": false,
    "Business finance": false,
    "Budgeting and costing": false,
  });

  const { userDetails, userId, instanceId } = useUserDetailsContext();

  React.useEffect(() => {
    timer.setStartTime(1);
  }, []);

  React.useEffect(() => {
    generatexAPIStatement({
      actorInput: userId || tempUserId,
      instanceInput: instanceId || tempInstanceId,
      verbInput: "attempted",
      questionInput: null,
      resultInput: null,
    });
  }, [userId, instanceId]);
  // Question section handlers start

  function handleEnterCategory(category: Category) {
    scrollToTop();
    setCategory(category);
  }

  function handleNextClick(category: Category) {
    scrollToTop();
    setCategory(null);
    setAnsweredCategories((prevState) => ({
      ...prevState,
      [category]: true,
    }));
  }

  function scrollToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  function handleFinishQuizClick(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const talliedScores = tallyScores(responses);
    setTalliedStores(talliedScores);

    const score = talliedScores.total;
    setFinalScore(score);
    generatexAPIStatement({
      actorInput: userId || tempUserId,
      instanceInput: instanceId || tempInstanceId,
      verbInput: "completed",
      questionInput: null,
      resultInput: null,
    });

    if (userDetails) {
      handleUserFormSubmit({
        fullName: userDetails?.fullName || "",
        phone: userDetails?.phone || "",
        email: userDetails?.email || "",
        organisation: userDetails?.organisation || "",
        roleType: userDetails?.roleType || "Unspecified",
      });
    } else {
      setSectionType(SectionType.SCORE);
    }
  }

  // Question section handlers end

  // Score section handlers start
  function handleGenerateReportClick() {
    setSectionType(SectionType.FORM);
  }

  function handleDoNotGenerateReportClick() {
    setShowFinalScore(true);

    timer.resetTimes();
  }

  function handleRetakeQuizClick() {
    setSectionType(SectionType.QUESTION);
    setShowFinalScore(false);
    resetResponses();
    setAnsweredCategories({
      "Understanding your organisation's financial reports": false,
      "Key financial ratios": false,
      "Business finance": false,
      "Budgeting and costing": false,
    });

    timer.setStartTime(1);
  }

  function resetResponses() {
    setResponses([]);
  }
  // Score section handlers end

  // Form section handlers start
  function handleUserFormSubmit(values: User) {
    const put = async () => {
      try {
        setLoading(true);
        setError(null);

        setLoadingMessage("Please wait, connecting");

        const userResponses = transformResponses(timer.times, responses);

        const adminCreateUserResponse = await adminCreateUser(
          values.email,
          USER_POOL_ID
        );

        setLoadingMessage("Please wait, collating data");

        let username = "";
        let userTempPassword = "";
        if (!adminCreateUserResponse?.data.success) {
          switch (adminCreateUserResponse?.data.data) {
            case "Request failed with status code 400":
              const userDetails = await getUsername(values.email);
              username = userDetails?.data.data.Users[0].Username;
              break;
            // TODO: add other failure handlers
          }
        } else {
          username = adminCreateUserResponse?.data.data.User.Username;
          userTempPassword =
            adminCreateUserResponse?.data.data.temporaryPassword;

          await addUserToGroup(username);

          setLoadingMessage("Please wait, loading assets");
          if (userDetails) {
            await createUserPortal(
              values,
              username,
              userTempPassword,
              tempInstanceId
            );
          }
        }
        setLoadingMessage("Please wait, generating report");

        // TODO: improve error handling e.g., if sendEmail succeeds and
        // createUser fails, the user will get an error msg despite the
        // email successfully being sent.
        if (userDetails) {
          await sendEmail(values, userResponses, talliedScores);
          setLoadingMessage("Please wait, sending email");
        }

        await createUser(values, userResponses, username, instanceId);

        if (userDetails) {
          const result = await deleteTempxAPIData(userId ? userId : "");
          if (result) {
            setSectionType(SectionType.NAVIGATE);
          }
        } else {
          deleteTempxAPIData(tempUserId);
          setSectionType(SectionType.END);
          setUserEmail(values.email);
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    put();
  }

  // Form section handlers end
  function renderContent(section: SectionType) {
    switch (section) {
      case SectionType.QUESTION:
        return (
          <QuestionSection
            handleNextClick={handleNextClick}
            handleFinishQuizClick={handleFinishQuizClick}
            responses={responses}
            setResponses={setResponses}
            category={category}
            handleEnterCategory={handleEnterCategory}
            answeredCategories={answeredCategories}
            tempUserId={tempUserId}
            tempInstanceId={tempInstanceId}
            loading={loading}
            loadingMessage={loadingMessage}
          />
        );
      case SectionType.NAVIGATE:
        return (window.location.href = document.referrer);
      case SectionType.SCORE:
        return (
          <ScoreSection
            showScore={showFinalScore}
            finalScore={finalScore.toString()}
            handleGenerateReportClick={handleGenerateReportClick}
            handleDoNotGenerateReportClick={handleDoNotGenerateReportClick}
            handleRetakeQuizClick={handleRetakeQuizClick}
          />
        );
      case SectionType.FORM:
        return (
          <FormSection
            handleSubmit={handleUserFormSubmit}
            loading={loading}
            error={error}
            loadingMessage={loadingMessage}
          />
        );
      case SectionType.END:
        return <EndSection email={userEmail} />;
      default:
        const _exhaustiveCheck: never = section;
        return _exhaustiveCheck;
    }
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100%" }}
    >
      <Header />
      <Container
        sx={{
          background: "white",
          overflow: "hidden",
          flex: 1,
        }}
        maxWidth={false}
      >
        {renderContent(sectionType)}
      </Container>
      <Footer sectionType={sectionType} />
    </div>
  );
}

export default function App() {
  return (
    <UserDetailsProvider>
      <AppInner />
    </UserDetailsProvider>
  );
}
