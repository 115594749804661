import Validator from "validator";

const generatePassword = (
  useUpperCharacters: Boolean,
  useLowerCharacters: Boolean,
  useSpecialCharacters: Boolean,
  useNumericCharacters: Boolean,
  passwordLength: number
) => {
  let charSetToUse = "";
  let generatedPassword = "";
  let config: any = {};
  if (useUpperCharacters) {
    charSetToUse += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    config.minUppercase = 1;
  }
  if (useLowerCharacters) {
    charSetToUse += "abcdefghijklmnopqrstuvwxyz";
    config.minLowercase = 1;
  }
  if (useSpecialCharacters) {
    charSetToUse += "!#$%&()*+,-.:;=?@[]^_{|}~";
    config.minSymbols = 1;
  }
  if (useNumericCharacters) {
    charSetToUse += "0123456789";
    config.minNumbers = 1;
  }

  while (!Validator.isStrongPassword(generatedPassword, config)) {
    generatedPassword = "";
    for (var i = 0; i < passwordLength; i++) {
      generatedPassword += charSetToUse.charAt(
        Math.floor(Math.random() * charSetToUse.length)
      );
    }
  }
  return generatedPassword;
};

export default generatePassword;
