import React from "react";
import "./style.css";

import { Grid, Button } from "@mui/material";
import { Category, QuestionResponse } from "../../../types";
import { QuestionsContainer } from "./components/QuestionsContainer";
import { CategoryCard } from "./components/CategoryCard";

interface QuestionSectionProps {
  handleNextClick: (category: Category) => void;
  handleFinishQuizClick: (event: React.FormEvent<HTMLFormElement>) => void;
  responses: QuestionResponse[];
  setResponses: React.Dispatch<React.SetStateAction<QuestionResponse[]>>;
  category: Category | null;
  handleEnterCategory: (category: Category) => void;
  answeredCategories: Record<Category, boolean>;
  tempUserId: string;
  tempInstanceId: string;
  loading: boolean;
  loadingMessage: string | null;
}

export const QuestionSection = ({
  handleNextClick,
  handleFinishQuizClick,
  responses,
  setResponses,
  category,
  handleEnterCategory,
  answeredCategories,
  tempUserId,
  tempInstanceId,
  loading,
  loadingMessage,
}: QuestionSectionProps) => {
  const answeredAllCategories = React.useMemo(
    () => !Object.values(answeredCategories).some((answered) => !answered),
    [answeredCategories]
  );

  return (
    <Grid
      container
      id="question-section-container"
      sx={{
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        padding: "2em",
        maxWidth: "1000px",
      }}
    >
      <form onSubmit={handleFinishQuizClick}>
        {category !== null ? (
          <QuestionsContainer
            category={category}
            handleNextClick={handleNextClick}
            responses={responses}
            setResponses={setResponses}
            tempUserId={tempUserId}
            tempInstanceId={tempInstanceId}
          />
        ) : (
          <>
            <Grid item id="category-list-section">
              {Object.values(Category).map((category, index) => (
                <CategoryCard
                  key={index}
                  category={category}
                  index={index}
                  handleEnterCategory={handleEnterCategory}
                  answeredCategories={answeredCategories}
                />
              ))}
            </Grid>
            <Button
              id="submit-button"
              variant="contained"
              type="submit"
              disabled={!answeredAllCategories || loading}
              style={{
                margin: "0 auto",
                marginTop: "2em",
                marginBottom: "2em",
                display: "flex",
                justifyContent: "center",
                width: "200px",
                backgroundColor: "#5986af",
              }}
            >
              {loading ? "Submitting..." : "  Submit"}
            </Button>
            {loadingMessage && (
              <div className="loadingMessage">{loadingMessage}</div>
            )}
          </>
        )}
      </form>
    </Grid>
  );
};
